<template>
  <div class="hotGoods">
    <div class="title">热门商品</div>
    <div class="main-card">
      <div class="tab">
        <div
          v-for="(item, i) in data"
          :key="i"
          class="tab-item"
          :class="index == i ? 'tab-item-active' : ''"
          @click="handleClick(item, i)"
        >
          {{ item.name }}
        </div>

        <el-popover
          v-model="visible"
          placement="bottom"
          width="410"
          trigger="hover"
        >
          <div style="display: flex; flex-wrap: wrap">
            <div
              style="width: 50%; padding: 10px"
              v-for="(item, i) in list"
              :key="i"
              id="tab-itema"
              :class="indexs == i ? 'tab-itema-active' : ''"
              @click="handleClicks(item, i)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="tab-items" slot="reference">
            查看更多
            <i class="el-icon-caret-bottom"></i>
          </div>
        </el-popover>
      </div>
    </div>

    <div class="main-card">
      <div class="goods-list">
        <HotGoods
          v-for="(item, i) in goodsList"
          :key="i"
          :data="item"
        ></HotGoods>
      </div>
    </div>
  </div>
</template>
<script>
import { hotGoodsCategory, hotGoods } from "@/api/home.js";
import HotGoods from "@/components/goods/HotGoods";
export default {
  components: {
    HotGoods,
  },
  data() {
    return {
      data: [
        "IPhone手机套",
        "蓝牙入耳耳机",
        "中性运动鞋",
        "3层一次性口罩",
        "可重复使用的KF94口罩",
        "头戴式面罩",
      ],
      list: [],
      index: 0,
      indexs: 0,
      visible: false,
      charu: false,
      goodsList: [],
    };
  },
  created() {
    this.getHotGoodsCategory();
  },
  methods: {
    getHotGoods() {
      hotGoods({
        category_id: this.data[this.index].category_id,
      }).then((res) => {
        if (res.code == 1) {
     
          this.goodsList = res.data;
        }
      });
    },
    handleClick(val, i) {
      this.index = i;
      this.list.forEach((item, index) => {
        if (val.category_id == item.category_id) {
          this.indexs = index;
        }
      });
      this.getHotGoods();
    },
    handleClicks(val, index) {
      this.indexs = index;

      let type = false;

      this.data.forEach((item, i) => {
        if (val.category_id == item.category_id) {
          this.index = i;
          type = true;
        }
      });
      if (type == false) {
        if (this.charu) {
  
          this.index = 0;
          this.data[0] = val;
        } else {
  
          this.index = 0;
          this.charu = true;
          this.data.pop();
          this.data.unshift(val);
        }
      }
      this.getHotGoods();
    },
    getHotGoodsCategory() {
      hotGoodsCategory().then((res) => {
        if (res.code == 1) {
          this.list = res.data.slice(0, 12);
          this.data = this.list.slice(0, 6);
          this.getHotGoods();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.hotGoods {
  background: #f5f5f5;
  padding-bottom: 30px;
  .title {
    font-size: 28px;
    color: #555;
    text-align: center;
    padding: 30px 0;
  }
  .tab {
    background: #fff;
    width: 100%;
    // padding: 20px 0;
    display: flex;
    justify-content: space-between;
    .tab-item {
      padding: 20px 30px;
    }
    .tab-items {
      padding: 20px 30px;
    }
    .tab-items:hover {
      cursor: pointer;
    }
    .tab-item:hover {
      color: #ee4d2d;
      border-bottom: 3px solid #ee4d2d;
      opacity: 0.6;
      cursor: pointer;
    }
    .tab-item-active {
      color: #ee4d2d;
      border-bottom: 3px solid #ee4d2d;
    }
  }
  .goods-list {
    display: flex;
    flex-wrap: wrap;
  }
}
#tab-itema:hover {
  color: #ee4d2d;
  cursor: pointer;
}
.tab-itema-active {
  color: #ee4d2d;
}
</style>