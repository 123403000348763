<template>
  <div class="hotGoodss">
    <div class="goods-item" @click="toGoodsInfo(data.id)">
      <div class="">
        <el-image
          style="
            width: 228px;
            height: 228px;
            text-align: center;
            line-height: 300px;
          "
          :src="data.image"
          fit="fit"
        >
          <svg
            slot="error"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 54 61"
            fill="#e5e4e4"
            style="width: 50px; height: 50px"
          >
            <path
              d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z"
            /></svg
        ></el-image>
        <div style="padding: 0 10px">
          <div class="name">{{ data.title }}</div>
          <div class="jiage">RM{{ data.price }}</div>
          <div style="height: 18px"></div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 100%;
              color: rgba(0, 0, 0, 0.26);
              font-size: 12px;
            "
          >
            <div>{{ data.sales }}已售</div>
            <div style="display: flex">
              <el-rate v-model="value1" disabled></el-rate>({{
                data.five_star
              }})
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HotGoods",
  props: ["data"],
  data() {
    return {
      value1: 5,
    };
  },
  methods: {
    toGoodsInfo(id) {
        
      this.$router.push({ path: "goodsInfo", query: { goodsId: id } });

    },
  },
};
</script>
<style lang="less" >
.hotGoodss {
  width: 240px;
  padding: 6px;
  .goods-item {
    background: #fff;
    padding-bottom: 10px;
    .name {
      min-height: 36px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
    }
    .jiage {
      font-size: 14px;
      color: #ee4d2d;
      margin-top: 4px;
    }
    .el-rate__icon {
      font-size: 6px;
      margin: 0;
    }
  }
  .goods-item:hover {
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
    transform: translateY(-2px);
    transition: all 200ms ease;
    z-index: 1;
    cursor: pointer;
  }
}
</style>